import type { Destination, DestinationType } from "@/types/types"

interface DestinationDetails {
  name: string
  description: string
  docsPath?: string // override path to docs site page
}

export const DESTINATION_TYPES: { [K in DestinationType]: DestinationDetails } =
  {
    axiom: { name: "Axiom", description: "Send events to Axiom." },
    blackhole: {
      name: "Black hole",
      description: "Experiment without sending data.",
    },
    datadog: {
      name: "Datadog",
      description: "Send metrics, logs, and traces to Datadog.",
    },
    honeycomb: { name: "Honeycomb", description: "Send events to Honeycomb." },
    http: { name: "HTTP", description: "Send events to any HTTP endpoint." },
    kafka: { name: "Kafka", description: "TBD" },
    logscale: {
      name: "LogScale",
      description: "Send events to CrowdStrike LogScale.",
    },
    open_telemetry: {
      name: "OpenTelemetry",
      description: "Send events in OpenTelemetry format.",
    },
    posthog: {
      name: "PostHog",
      description: "Send events to Posthog.",
    },
    prometheus_remote_write: {
      name: "Prometheus Remote-Write",
      description: "Send events as Remote-Write requests.",
    },
    s3: {
      name: "Amazon S3",
      description: "Push events to an Amazon S3 bucket.",
      docsPath: "amazon_s3",
    },
  }

// Given a destination obj or destination type, return the path to its
// SVG image
export const destinationImagePath = (
  dest: Destination | DestinationType | null | undefined
) => {
  if (!dest) return ""
  const dType = isDestination(dest) ? dest.type : dest
  return `/images/destinations/${dType}.svg`
}

// Given a destination obj or destination type, return the correct
// user-friendly name to display in the UI
export const destinationTypeName = (
  dest: Destination | DestinationType | null | undefined
) => {
  if (!dest) return ""
  const dType = isDestination(dest) ? dest.type : dest
  const destinationType = dType && DESTINATION_TYPES[dType]
  return destinationType?.name || ""
}

export const destinationTypeDescription = (
  dest: Destination | DestinationType | null | undefined
) => {
  if (!dest) return ""
  const dType = isDestination(dest) ? dest.type : dest
  const destinationType = dType && DESTINATION_TYPES[dType]
  return destinationType?.description || ""
}

export const destinationTypeDocsPath = (dType: DestinationType): string => {
  if (!dType) return ""
  const destinationDetails = DESTINATION_TYPES[dType]
  if (!destinationDetails) return ""
  const lastSegment = destinationDetails.docsPath || dType
  return `destinations/${lastSegment}`
}

// type guard to check if object is a destination
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isDestination = (obj: any): obj is Destination => {
  // we can't check inclusion of DestinationType because it is a string
  // literal union, use DESTINATION_TYPES as a proxy
  return (
    typeof obj === "object" && Object.keys(DESTINATION_TYPES).includes(obj.type)
  )
}
